;(function($){

	function Screen() {
		var _ =  this;
		var height = $(window).height();
		
		$('.screen').css('min-height', height+"px");
		

		$(window).resize(function() {
			var height2 = $(window).height();
            $('.screen').css('min-height', height2+"px");
        });

        $(document).ready(function() { 
	        setTimeout(function(){ 
	        	$('.screen').animate({ opacity: 1 }, 200);
	        }, 500);

	        setTimeout(function(){ 
	        	$('.section-header').animate({ opacity: 1 }, 200);
	        }, 800);
        });

		
	}
	
	new Screen();

}(jQuery));




