;(function($){

    function Contact() {
        var _ = this;

        var $formContact = $("#form-contact").validate({
            rules: {
                name: { required: true },
                email: { required: true, email: true },
                cellphone: { required: true },
                message: { required: true },
            },
            messages: {
                name: { required: "Informe seu nome" },
                email: { required: 'Informe o seu email', email: 'Ops, informe um email válido' },
                cellphone: { required: "Informe o nº do seu Celular" },
                message: { required: "Informe a mensagem" },
            },
            invalidHandler: function(e) {
                swal({
                    title: "OPS! Você não preencheu todos os campos!",
                    text: "Preencha todos os campos e tente novamente.",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "OK!",
                    closeOnConfirm: true
                });
            },
            submitHandler: function( form ) {

                $("#form-contact .btn-send").html("Enviando...");

                var myform = $(form).serialize();
                
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: $("#app_url").val() + "/api/contact",
                    data: myform,
                    success: function(result)
                    {
                        swal({
                            title: result.message,
                            text: "Click no botão para fechar!",
                            type: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Fechar!",
                            closeOnConfirm: true
                        });

                        $("#form-contact .btn-send").html("ENVIAR");

                        form.reset();
                        grecaptcha.reset();
                    },
                    error: function(response) {
                        var text = $.parseJSON(response.responseText);
                        
                        swal({
                            title: (text.error) ? text.error : "Ops! Houve um erro ao enviar. Tente novamente.",
                            text: "Click no botão para fechar!",
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Fechar!",
                            closeOnConfirm: true
                        });
                        
                        $("#form-contact .btn-send").html("ENVIAR");
                    }
                });
            }
        });

    }

    new Contact();

}(jQuery));
 
 
