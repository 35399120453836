;(function($){



    function Modal() {
    	var _  = this;
    	_.API  = $("#app_url").val() + "/";

    	_.uri  = null;

    	_.modalWidth = null;
    	_.openFullsreen = false;
    	_.modalTitle = null;
    	_.modalCor = null;

    	$(document).on('click', '.bt-open-modal', function(e) {
    		e.preventDefault();

    		var btn = this;

    		if(window.matchMedia("(max-width: 480px)").matches) {
    			_.modalWidth = "100%";
    			_.openFullsreen = true;
    		} else if(window.matchMedia("(max-width: 1300px)").matches) {
    			_.modalWidth = "90%";
    			_.openFullsreen = false;
    		} else if (window.matchMedia("(max-width: 1920px)").matches) {
    			_.modalWidth = "70%";
    			_.openFullsreen = false;
    		}

    		_.modalTitle = $(btn).attr('data-title');
    		_.modalCor = $(btn).attr('data-cor');
    		_.uri = $(btn).attr('data-href');

    		_.init()
    	})
    }



    Modal.prototype.init =  function() {
    	var _ =  this;


    	$("#modal").iziModal({		
			title: _.modalTitle,
			headerColor: _.modalCor,
			background: '#108810',
			bodyOverflow: true,
			width: _.modalWidth,
			overlayColor: 'rgba(0, 0, 0, 0.65)',
            history: false,
    		autoOpen: true,
    		fullscreen: true,
    		openFullscreen: _.openFullsreen,
    		closeButton: true,
    		restoreDefaultContent: true,

			onOpened: function(){
				_.modal.stopLoading();

				$('.slider-modal .item').css('display', 'block');
				$('.slider-modal').animate({ opacity: 1 }, 200);
                $('.slider-modal').slick('setPosition');
			},
		    onOpening: function(modal){	
		    	_.modal = modal;
		        modal.startLoading();

		        $.get( _.API + _.uri, function(data) {
		            $("#modal .iziModal-content").html(data); 

                    $('.slider-modal').slick({
                        dots: true,
                        arrows: false,
                        infinite: false,
                        speed: 500,
                        autoplay: true,
                        autoplaySpeed: 3500
                    });		   		 		    
		        });
		    },
		    onClosed: function() {
		    	$('.area-modal').html("<div id='modal'></div>");
		    }		 

		});

    };

   	new Modal();

}(jQuery));

 

 

 

 

