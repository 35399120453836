;(function($){

    function Carousel() {
   	 	var _ =  this;

   	 	_.menu();
   	}

 	Carousel.prototype.menu =  function() {
		new Vue({
		  el: '#menu-carousel',
		  components: {
		    'carousel-3d': Carousel3d.Carousel3d,
		    'slide': Carousel3d.Slide
		  },
		  data: {
		  	liberado: true
		  },
		  methods: {
		    goToSlide(index) {
		      	this.$refs.mycarousel.goSlide(index)
		    },
		    onBeforeSlideChange(index) {

		    },
		    handleScroll (event) {
		      	var height = $(window).height();
            	if (document.documentElement.scrollTop >= 0 && document.documentElement.scrollTop < height-100) {
              		this.goToSlide(0)
            	}

            	if (document.documentElement.scrollTop >= height-100 && document.documentElement.scrollTop < (height * 2)-100) {
              		this.goToSlide(1)
            	}

            	if (document.documentElement.scrollTop >= (height * 2)-100 && document.documentElement.scrollTop < (height * 3)-100) {
              		this.goToSlide(3)
            	}

            	if (document.documentElement.scrollTop >= (height * 3)-100) {
              		this.goToSlide(2)
            	}   
		    }
		  },
		  created () {
		    window.addEventListener('scroll', this.handleScroll);
		  },
		  destroyed () {
		    window.removeEventListener('scroll', this.handleScroll);
		  },
		  computed: {
		  	dynamicWidth() {
		  		var width = 0;

		  		if(window.matchMedia("(max-width: 480px)").matches) {
		  			width = 140;
		  		} else {
		  			width = 291;
		  		}

		  		return width;
		  	},
		  	dynamicHeight() {
		  		var height = 0;

		  		if(window.matchMedia("(max-width: 480px)").matches) {
		  			height = 96;
		  		} else {
		  			height = 199;
		  		}

		  		return height;
		  	},

		  	dynamicSpace() {
		  		var space = 0;

		  		if(window.matchMedia("(max-width: 480px)").matches) {
		  			space= 150;
		  		} else {
		  			space = 300;
		  		}

		  		return space;
		  	},
		  }
		});
	}
	    
   	new Carousel();

}(jQuery));