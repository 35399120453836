;(function($){

	function Slides() {
		var _ =  this;

		$('.slider-modal').slick({
			dots: true,
			arrows: false,
			infinite: false,
			speed: 500,
			fade: true,
			autoplay: true,
			autoplaySpeed: 2500
		});

		$('.promo_slider_brmania').slick({
			dots: true,
			arrows: false,
			infinite: true,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 2000
		});

		$('.promo_slider_arena').slick({
			dots: true,
			arrows: false,
			infinite: true,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 2000
		});
		
	}
	
	new Slides();

}(jQuery));




